import React, { useState } from 'react'
import { Input, Button, Checkbox } from 'semantic-ui-react'

type TypeTodo = {
  name: string
  isChecked: boolean
}

const Todo: React.FC = () => {
  const [newTodo, setNewTodo] = useState('')
  const [todos, setTodos] = useState([
    { name: '本を借りる', isChecked: true },
    { name: '洗濯をする', isChecked: false },
  ])

  const handleAddnewTodo = () => {
    setTodos([...todos, { name: newTodo, isChecked: false }])
    setNewTodo('')
  }

  return (
    <>
      <Input
        placeholder="Add new todo..."
        value={newTodo}
        onChange={(e) => setNewTodo(e.target.value)}
      />
      <Button onClick={(e) => handleAddnewTodo()}>add</Button>

      {todos.map((todo, index) => (
        <div key={index}>
          <Checkbox label={todo.name} defaultChecked={todo.isChecked} />
          <br />
        </div>
      ))}
    </>
  )
}
export default Todo
